<template>
  <div class="oper-panel" style="overflow:auto;" v-if="!onlyOne">
    <Input
      class="oper-panel-search"
      prefix="md-search"
      v-model="search"
      placeholder="搜索组件名称模糊搜索"
    />
    <Collapse v-model="openIndex" simple>
      <Panel
        :name="key"
        v-for="(val, key) in pageItems"
        :key="key"
        v-show="showComponents[key]"
        hide-arrow
      >
        <i class="ivu-icon icon-full-right iconfont"></i> {{ val[0].groupName }}
        <div class="buttonGroup" slot="content">
          <!-- permsChecker.cachePerms[item.type].showOperBtn会有new Promise的情况 ,需要===true时才显示-->
          <Button
            class="oper-btn"
            type="primary"
            v-for="(item, index) in val"
            :key="index"
            @click="clickBtn(item, key)"
            v-show="
              showComponents[key] &&
                showComponents[key][index] &&
                item.show !== false &&
                permsChecker.cachePerms[item.type].showOperBtn === true
            "
          >
            <i
              :class="item.icon"
              class="btn-icon"
              :style="{ color: item.color }"
              v-if="!item.svg"
            ></i>
            <img
              class="btn-svg"
              :src="
                require(`@/components/decorate/decorate-assets/svg/decorateIcon/${item.svg}.svg`)
              "
              alt
              v-else
            />
            {{ item.name }}
          </Button>
        </div>
      </Panel>
    </Collapse>
    <p class="no-result" v-show="!noResult">
      <i class="icon-fenxiao-leijiyongjin- iconfont"></i>
      <span>暂无结果</span>
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { getModal, groupInfo } from "@/store/decorate/index.js";
import { scrollTo } from "@/store/decorate/utils.js";
import initPageDecoratePerm from "@/store/decorate/templatePerms";
export default {
  computed: {
    ...mapGetters("decorate", ["getModal", "getAllModalName", "getPageItems"]),
    ...mapState("decorate", {
      pageList: (state) => state.pageList,
      topItem: (state) => state.topItem,
      currentModal: (state) => state.currentModal,
      onlyOne: (state) => state.onlyOneComponent,
    }),
    pageItems() {
      let items = this.getPageItems(this.$route.params.page);
      let groups = Object.keys(items).sort((a, b) => {
        return groupInfo(a).yIndex - groupInfo(b).yIndex;
      });
      let newItem = {};
      groups.forEach((item) => {
        newItem[groupInfo(item).yIndex] = items[item];
      });
      return newItem;
    },
  },
  watch: {
    search: {
      immediate: true,
      handler() {
        if (this.search.trim()) {
          let res = this.pageItems;
          let tmp = {};
          for (let k in res) {
            res[k].forEach((item, index) => {
              if (
                item.type.indexOf(this.search) > -1 ||
                item.name.indexOf(this.search) > -1
              ) {
                if (tmp[k]) {
                  tmp[k][index] = item;
                } else {
                  tmp[k] = {
                    [index]: item,
                  };
                }
              }
            });
          }
          this.showComponents = tmp;
        } else {
          this.showComponents = this.pageItems;
        }
        this.noResult = Object.keys(this.showComponents).length;
      },
    },
    pageItems: {
      immediate: true,
      handler() {
        let res = this.pageItems;
        this.showComponents = this.pageItems;
        if (this.onlyOne == true) {
          this.clickBtn(res[5][0], "global");
        }
      },
    },
  },
  data() {
    return {
      noResult: false, //无搜索结果
      search: "",
      showComponents: {},
      openIndex: ["1", "2", "3", "4", "5", "6"],
      permsChecker: {},
    };
  },
  created() {
    this.permsChecker = initPageDecoratePerm();
  },
  methods: {
    hasItem(arr, obj, key) {
      let index = -1;
      arr.forEach((v, i) => {
        if (v[key] == obj[key]) {
          index = i;
        }
      });
      return index;
    },
    async clickBtn(item, key) {
      //添加元素
      let modal = getModal(item.type); //添加模板
      if (modal.istop && this.topItem.length) {
        //已经有了置顶元素,置顶元素只能有一个，除了跟followbar一起可以有两个
        let index = this.hasItem(this.topItem, modal, "id"); //查找相同id
        let errorMessage = "";
        if (index > -1) {
          //（两个置顶元素相同）
          errorMessage = "此元素最多允许添加1个";
        } else if (
          modal.id != "followbar" &&
          ((this.topItem.length == 1 && this.topItem[0].id != "followbar") ||
            this.topItem.length > 1)
        ) {
          //两个置顶元素不同,只能与followbar共存
          index = 0;
          errorMessage = "已有顶部固定元素，请删除后添加";
        }
        if (errorMessage) {
          this.$Message["error"]({
            background: true,
            content: errorMessage,
          });
          this.changeFocus({
            item: this.topItem[index],
            pageId: this.$route.params.page,
          });
          return;
        }
      }
      item.groupType = key;
      if (modal.max) {
        //查看最大允许添加数量
        let hasnum = 0;
        let currentItem;
        this.pageList.forEach((item) => {
          if (item.id == modal.id) {
            hasnum = hasnum + 1;
            currentItem = item;
          }
        });
        if (hasnum >= modal.max) {
          this.$Message["error"]({
            background: true,
            content: `此元素最多允许添加${modal.max}个`,
          });
          this.changeFocus({
            item: currentItem,
            pageId: this.$route.params.page,
          });
          return;
        }
      }
      this.addModal({
        list: item,
        pageId: this.$route.params.page,
      }).then((items) => {
        let res = items[0];
        if (res) {
          let status = true;
          if (res.id == "diymenu") {
            status = false;
          }
          scrollTo(res, status);
        }
      });
    },
    ...mapActions("decorate", ["addModal"]),
    ...mapMutations("decorate", ["changeFocus"]),
  },
};
</script>

<style lang="scss" scoped>
.oper-panel {
  position: absolute;
  width: 306px;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 22px;
  z-index: 0;
  /* global/white */
  background: #ffffff;
  /deep/ .oper-panel-search {
    border: none;
    width: 100%;
    height: 46px;
    border-bottom: 1px solid #e9edef;
    padding-left: 20px;
    .ivu-input-prefix {
      padding-left: 20px;
    }
    .ivu-input,
    .ivu-icon {
      border: none !important;
      box-shadow: none;
      height: 100%;
      color: #000;
    }
    .ivu-icon-ios-loading {
      display: none !important;
    }
    .ivu-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  /deep/ .ivu-collapse-header,
  .ivu-collapse-item,
  .ivu-collapse-item,
  .ivu-collapse-simple {
    border: none;
    font-weight: bold;
    color: #262b30;
    .ivu-icon {
      font-size: 12px;
      margin-right: 4px;
      vertical-align: 0;
      color: #939799;
    }
  }
  /deep/ .ivu-collapse-content {
    padding: 0 0 0 15px;
    .buttonGroup {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .ivu-btn {
        position: relative;
        margin: 5px;
        width: 102px;
        height: 102px;
        box-sizing: border-box;
        padding: 0;
        /* global/bg */
        background: #f4f6f8;
        border-radius: 2px;
        border: none;
        span {
          font-family: PingFang SC;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #636669;
          display: flex;
          flex-direction: column;
          i {
            font-size: 24px;
            margin-bottom: 10px;
          }
        }
        &:disabled {
          filter: grayscale(100%);
          img,
          .iconfont {
            opacity: 0.6;
          }
          span {
            color: #939799;
          }
        }
        .cover {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.7);
          font-weight: 400;
          font-size: 12px;
          color: #fff;
          display: none;
          flex-direction: column;
          justify-content: center;
          border-radius: 2px;
          transition: all 0.5s;
          p {
            text-align: center;
          }
        }
        &:hover {
          border: 1px solid $brand-color;
          background: #f0faff;
          span {
            color: $brand-color;
          }
          .cover {
            display: flex;
          }
        }
      }
      .btn-svg,
      .btn-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
        text-align: center;
        line-height: 24px;
        margin: 11px auto;
      }
    }
  }
  .no-result {
    display: flex;
    flex-direction: column;
    i,
    span {
      margin: 0 auto;
    }
    i {
      font-size: 28px;
      width: 28px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      margin-bottom: 10px;
      margin-top: 60px;
    }
    span {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #636669;
    }
  }
}
</style>
